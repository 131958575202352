<template>
    <UiSelect
        :model-value="modelValue"
        placeholder="Rechercher parmi les enfants"
        :options="registrationsOptions"
        name="registration_uuid"
        :loading="loadingStates['registrations.search']"
        @update:model-value="selectRegistration"
        @searched="onSearched"
    />
</template>

<script>
import { defineComponent } from 'vue'
import { mapStores } from 'pinia'
import { loadingMixin } from 'abcreche-ui'
import { useRegistrationsStore } from '~/stores/registrations.js'
import registrationsListMixin from '~/mixins/registrations/registrationsListMixin.js'

export default defineComponent({
    mixins: [registrationsListMixin, loadingMixin],

    props: {
        modelValue: {
            type: [String, Array],
            default: null
        }
    },

    emits: ['update:model-value', 'select-registration'],

    computed: {
        ...mapStores(useRegistrationsStore)
    },

    created () {
        if (this.modelValue) {
            this.registrationsQueryParameters.registration_uuids = [this.modelValue]
            this.registrationsStore.$reset()
            this.registrationsStore.filters.status = []
            this.searchRegistrations()
        }
    },

    methods: {
        onSearched (value) {
            this.registrationsStore.$reset()
            this.registrationsStore.filters.status = []
            this.registrationsQueryParameters.registration_uuids = []
            this.registrationsQueryParameters.search = value
            this.searchRegistrations()
        },

        selectRegistration (registrationUuid) {
            this.$emit('update:model-value', registrationUuid)
            this.$emit('select-registration', this.registrations.find(registration => registration.uuid === registrationUuid))
        }
    }

})
</script>
